<template lang="">
    <div>
        <div class="relative">
            <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                <div class="px-2 py-2 mb-3 bg-white shadow rounded-lg" v-for="post in BlogItems" :key="post.id">
                    <div class="flex relative">
                        <img class="w-12 h-12 rounded-full" v-bind:src="post.postableImage"/>

                        <div class="ml-2 mt-0.5">
                            <span class="block font-semibold">{{ post.postableName }}</span>
                            <span class="block text-sm font-sans text-gray-500">{{ post.date }} at {{ post.time }}</span>
                        </div>

                        <div class="absolute inset-y-2 right-0 flex">
                            <div v-if="post.views" class="flex flex-row bg-gray-200 rounded-md px-2 py-1">
                                <p class="font-semibold">{{ post.views }} </p>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <p class="overflow-hidden text-blue-500 mt-3 text-justify" v-html="post.title"></p>

                    <p class="ck-content text-justify text-gray-700 h-10" v-html="post.body.substring(0, 90) + '...'"></p>
                    <router-link :to="`/my/blog/${ post.id }`" class="underline font-medium">Read Full Post</router-link>
                    
                    <img class="object-cover w-full h-56" v-bind:src="post.image"/>

                    <router-link :to="`/my/blog/${ post.id }`">
                        <div class="flex justify-between items-center mt-2 mx-2">
                            <div class="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                </svg>
                                <span class="ml-1 mt-0.5 text-gray-500 font-semibold">Like</span>
                            </div>

                            <div class="flex" @click="showWriteComment = showWriteComment == post.id ? '' :  post.id">
                                <svg xmlns="http://www.w3.org/2000/svg" class=" h-5 w-5 md:h-6 md:w-6 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                </svg>
                                <span class="ml-1 mt-0.5 text-gray-500 font-semibold">Comments</span>
                            </div>

                            <div class="flex" @click="showShare = showShare == post.id ? '' :  post.id">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6" viewBox="0 0 512 512">
                                    <path d="M503.7 226.2l-176 151.1c-15.38 13.3-39.69 2.545-39.69-18.16V272.1C132.9 274.3 66.06 312.8 111.4 457.8c5.031 16.09-14.41 28.56-28.06 18.62C39.59 444.6 0 383.8 0 322.3c0-152.2 127.4-184.4 288-186.3V56.02c0-20.67 24.28-31.46 39.69-18.16l176 151.1C514.8 199.4 514.8 216.6 503.7 226.2z"/>
                                </svg>
                                <span class="ml-1 mt-0.5 text-gray-500 font-semibold">Share</span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="text-center mt-5">
            <BtnVue router-path="/my/blog" variant="info">Show All</BtnVue>
        </div>
    </div>
</template>

<script>
import BtnVue from '../../../../components/btn.vue';

export default {
    name: 'blogs',
    components: {
        BtnVue,
    },
    computed: {
        BlogItems(){
            return Array.isArray(this.data.items) ? this.data.items:[];
        }
    },
    props: {
        data: {
            type: Object,
            default: {}
        }
    }
}
</script>

<style lang="">
    
</style>