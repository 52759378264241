<template>
    <div class="max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 p-4">
        <div class="flex flex-col items-center">
            <img :src="teacher.image" alt="" class="mb-3 w-24 h-24 rounded-full shadow-lg" />
            <h5 class="text-xl font-medium text-gray-900 dark:text-white text-center">{{ teacher.name }}</h5>
            <p class="ck-content text-sm text-gray700 dark:text-gray-400" v-html="teacher.designation"></p>
            <p style="height: 170px" class="ck-content text-sm text-gray-500 dark:text-gray-400 pt-3 overflow-hidden" v-html="teacher.description"></p>
        </div>

        <div class="text-right mt-6">
            <router-link :to="`/teachers-panel/${ teacher.id }`" class="font-medium">see more</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'teacher-item',
        components: {

        },
        props: {
            teacher: {
                type: Object,
                default: {}
            }
        }
    }
</script>

<style scoped>

</style>