<template>
    <router-link :to="to" class="p-2 w-full border rounded-lg bg-white shadow flex justify-between items-center">
        <div class="w-28 p-2">
            <slot name="icon" />
        </div>
        <div class="w-full h-full flex flex-col justify-start pl-2">
            <div class="text-left font-bold text-blue-600">
                <slot name="title" />
            </div>
            <div class="text-left text-xs lg:text-sm text-gray-500">
                <slot name="body" />
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'quick-link-card',
    props: {
        to: { type: String, default: '#' }
    },
}
</script>