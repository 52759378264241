<template>

  <quick-link-card class="shadow-lg" to="#free-class">
    <template #icon>
      <svg-free-class class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Free Class</div>
    </template>
    <template #body>Please attend our free classes and stay with us</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="#model-class">
    <template #icon>
      <svg-model-test class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Model Test</div>
    </template>
    <template #body>Win the competition through the model test</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="/notices">
    <template #icon>
      <svg-notice class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Notice</div>
    </template>
    <template #body>Stay updated by looking at the notices.</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="#question-bank">
    <template #icon>
      <svg-question-bank class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Question Bank</div>
    </template>
    <template #body>See the exam questions of the past years</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="/branches">
    <template #icon>
      <svg-branches class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Branches</div>
    </template>
    <template #body>Visit the website of our branches</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="/courses">
    <template #icon>
      <svg-courses class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Courses</div>
    </template>
    <template #body>Check out our courses and get admitted</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="/teachers-panel">
    <template #icon>
      <svg-teachers-panel class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">Teachers Panel</div>
    </template>
    <template #body>We have a panel of experienced teachers</template>
  </quick-link-card>

  <quick-link-card class="shadow-lg" to="#model-class">
    <template #icon>
      <svg-e-library class="w-full text-orange-500" />
    </template>
    <template #title>
      <div class="text-orange-500">E-Library</div>
    </template>
    <template #body>We have a rich e-library. Visit now</template>
  </quick-link-card>

</template>

<script>

import QuickLinkCard from './quick-link-card.vue';
import SvgFreeClass from './svg/free-class.vue';
import SvgModelTest from './svg/model-test.vue';
import SvgNotice from './svg/notice.vue';
import SvgQuestionBank from './svg/question-bank.vue';
import SvgBranches from './svg/branches.vue';
import SvgCourses from './svg/courses.vue';
import SvgTeachersPanel from './svg/teachers-panel.vue';
import SvgELibrary from './svg/e-library.vue';

export default {
  name: 'quick-link-item',
  components: {
    QuickLinkCard,
    SvgFreeClass,
    SvgModelTest,
    SvgNotice,
    SvgQuestionBank,
    SvgBranches,
    SvgCourses,
    SvgTeachersPanel,
    SvgELibrary,
  }
};
</script>

<style scoped>
</style>