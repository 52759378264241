<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.477 59.477">
        <g id="Group_2675" data-name="Group 2675" transform="translate(0 0)">
            <path id="Path_95" data-name="Path 95" d="M58.316,50.184H50.185V5.808a5.776,5.776,0,0,0-1.721-4.126A5.733,5.733,0,0,0,44.4,0H5.809A5.815,5.815,0,0,0,0,5.808V8.132A1.162,1.162,0,0,0,1.163,9.293H9.294V53.669A5.815,5.815,0,0,0,15.1,59.477H53.67a5.815,5.815,0,0,0,5.808-5.808V51.345A1.162,1.162,0,0,0,58.316,50.184ZM9.294,6.97H2.324V5.808A3.489,3.489,0,0,1,5.809,2.323h0A3.489,3.489,0,0,1,9.294,5.808Zm9.293,46.7a3.485,3.485,0,0,1-6.97,0V5.808a5.778,5.778,0,0,0-1.165-3.485H44.37a3.42,3.42,0,0,1,2.456,1.008,3.469,3.469,0,0,1,1.035,2.477V50.184H19.749a1.162,1.162,0,0,0-1.162,1.162Zm38.567,0a3.489,3.489,0,0,1-3.485,3.485H19.746a5.779,5.779,0,0,0,1.164-3.485V52.507H57.154v1.162Z" transform="translate(-0.001 0)" fill="currentColor"/>
            <path id="Path_96" data-name="Path 96" d="M161.162,62.323h19.981a1.162,1.162,0,1,0,0-2.323H161.162a1.162,1.162,0,0,0,0,2.323Z" transform="translate(-141.414 -53.03)" fill="currentColor"/>
            <path id="Path_97" data-name="Path 97" d="M220.911,101.334H206.158a1.162,1.162,0,0,0,0,2.323h14.754a1.162,1.162,0,0,0,0-2.323Z" transform="translate(-181.183 -89.562)" fill="currentColor"/>
            <path id="Path_98" data-name="Path 98" d="M160,350.5a1.162,1.162,0,0,0,1.162,1.162h19.981a1.162,1.162,0,1,0,0-2.323H161.162A1.162,1.162,0,0,0,160,350.5Z" transform="translate(-141.414 -308.754)" fill="currentColor"/>
            <path id="Path_99" data-name="Path 99" d="M161.162,392.992h19.981a1.162,1.162,0,1,0,0-2.323H161.162a1.162,1.162,0,1,0,0,2.323Z" transform="translate(-141.414 -345.287)" fill="currentColor"/>
            <path id="Path_100" data-name="Path 100" d="M227.929,202.716A4.066,4.066,0,1,0,221,199.829a1.162,1.162,0,0,0,2.323,0,1.742,1.742,0,1,1,2.962,1.244c-1.349,1.349-2.381,2.5-2.381,4.037a1.162,1.162,0,0,0,2.323,0C226.229,204.592,226.658,203.988,227.929,202.716Z" transform="translate(-195.328 -173.022)" fill="currentColor"/>
            <path id="Path_101" data-name="Path 101" d="M247.163,296.232A1.162,1.162,0,0,0,246,297.394h0a1.162,1.162,0,1,0,1.162-1.162Z" transform="translate(-217.424 -261.82)" fill="currentColor"/>
            <path id="Path_102" data-name="Path 102" d="M181.142,142.667H161.162a1.162,1.162,0,1,0,0,2.323h19.981a1.162,1.162,0,1,0,0-2.323Z" transform="translate(-141.414 -126.094)" fill="currentColor"/>
            <path id="Path_103" data-name="Path 103" d="M161.159,103.657a1.162,1.162,0,0,0,0-2.323h0a1.162,1.162,0,0,0,0,2.323Z" transform="translate(-141.411 -89.562)" fill="currentColor"/>
        </g>
    </svg>

</template>