<template>
    <div class="flex flex-row bg-gray-300 rounded">
        <div class="bg-blue-600 px-4 md:px-14 py-1 rounded-l">
            <p class="font-semibold text-white text-lg">Notice:</p>
        </div>
        <div class="py-1 mt-1 overflow-hidden">
            <Vue3Marquee :pauseOnHover="true">
                <div v-for="notice in NoticeItems">
                    <router-link :to="`/notices/${ notice.id }`" class="mx-3 font-medium">{{ notice.title }}</router-link>
                </div>
            </Vue3Marquee>
        </div>
    </div>
</template>

<script>
    import { Vue3Marquee } from 'vue3-marquee';
    export default {
        name: 'notice-scroll',
        components: {
            Vue3Marquee
        },
        computed: {
            NoticeItems(){
                return Array.isArray(this.data.items) ? this.data.items:[];
            }
        },
        props: {
            data: {
                type: Object,
                default: {}
            }
        }
    }
</script>

<style scoped>

</style>