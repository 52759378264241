<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.02 48.02">
    <g id="noun_Video_Tutorial_2019042" data-name="noun_Video Tutorial_2019042" transform="translate(-149 317)">
        <g id="Group_133" data-name="Group 133" transform="translate(149 -317)">
        <g id="Group_119" data-name="Group 119" transform="translate(11.705 41.042)">
            <path id="Path_19" data-name="Path 19" d="M47.663,57.276H25.529A2.372,2.372,0,0,0,23.278,55.7a2.448,2.448,0,0,0-2.251,1.576H17.35a.75.75,0,1,0,0,1.5h3.677a2.372,2.372,0,0,0,2.251,1.576,2.448,2.448,0,0,0,2.251-1.576H47.663a.709.709,0,0,0,.75-.75A.752.752,0,0,0,47.663,57.276ZM23.278,58.851a.825.825,0,1,1,.825-.825A.833.833,0,0,1,23.278,58.851Z" transform="translate(-16.6 -55.7)" fill="currentColor"/>
        </g>
        <g id="Group_120" data-name="Group 120" transform="translate(4.502 41.042)">
            <path id="Path_20" data-name="Path 20" d="M7.75,55.7a.709.709,0,0,0-.75.75v3.226a.709.709,0,0,0,.75.75.709.709,0,0,0,.75-.75V56.45A.709.709,0,0,0,7.75,55.7Z" transform="translate(-7 -55.7)" fill="currentColor"/>
        </g>
        <g id="Group_121" data-name="Group 121" transform="translate(6.903 41.042)">
            <path id="Path_21" data-name="Path 21" d="M10.95,55.7a.709.709,0,0,0-.75.75v3.226a.75.75,0,1,0,1.5,0V56.45A.709.709,0,0,0,10.95,55.7Z" transform="translate(-10.2 -55.7)" fill="currentColor"/>
        </g>
        <g id="Group_122" data-name="Group 122" transform="translate(4.502 3.92)">
            <path id="Path_22" data-name="Path 22" d="M7.825,35.018c3.677-.45,12.455-.525,17.932,1.276v1.651a.709.709,0,0,0,.75.75H43.315a.788.788,0,0,0,.675-.375l1.951-3.6a.562.562,0,0,0,.075-.375V7.707a.8.8,0,0,0-.675-.75c-6.3-.675-12.98-1.5-18.833.675-5.852-2.176-12.53-1.351-18.833-.675A.74.74,0,0,0,7,7.707V34.268a.584.584,0,0,0,.225.525A.852.852,0,0,0,7.825,35.018Zm24.31-18.233V28.04H20.881V16.785ZM42.865,37.119H27.258v-.9a32.7,32.7,0,0,1,8.1-1.351c2.7-.15,7.353,0,8.7.075ZM44.516,8.382v25.06c-5.177-.375-12.23-.3-17.257,1.2h0v-5.1h5.627a.709.709,0,0,0,.75-.75V16.035a.709.709,0,0,0-.75-.75H27.258V8.907C32.436,7.106,38.288,7.707,44.516,8.382ZM8.5,8.382c6.228-.675,12.08-1.276,17.257.6V15.36H20.13a.709.709,0,0,0-.75.75V28.865a.709.709,0,0,0,.75.75h5.627v5.1c-5.027-1.5-12.08-1.576-17.257-1.2Z" transform="translate(-7 -6.224)" fill="currentColor"/>
        </g>
        <g id="Group_123" data-name="Group 123" transform="translate(8.629 8.403)">
            <path id="Path_23" data-name="Path 23" d="M13.25,13.7h7.278a.75.75,0,1,0,0-1.5H13.25a.709.709,0,0,0-.75.75A.807.807,0,0,0,13.25,13.7Z" transform="translate(-12.5 -12.2)" fill="currentColor"/>
        </g>
        <g id="Group_124" data-name="Group 124" transform="translate(8.629 14.481)">
            <path id="Path_24" data-name="Path 24" d="M13.25,21.8h6.228a.75.75,0,0,0,0-1.5H13.25a.709.709,0,0,0-.75.75A.807.807,0,0,0,13.25,21.8Z" transform="translate(-12.5 -20.3)" fill="currentColor"/>
        </g>
        <g id="Group_125" data-name="Group 125" transform="translate(8.629 20.483)">
            <path id="Path_25" data-name="Path 25" d="M13.25,29.8h6.228a.75.75,0,1,0,0-1.5H13.25a.709.709,0,0,0-.75.75A.752.752,0,0,0,13.25,29.8Z" transform="translate(-12.5 -28.3)" fill="currentColor"/>
        </g>
        <g id="Group_126" data-name="Group 126" transform="translate(8.629 26.561)">
            <path id="Path_26" data-name="Path 26" d="M13.25,37.9h6.228a.75.75,0,1,0,0-1.5H13.25a.709.709,0,0,0-.75.75A.752.752,0,0,0,13.25,37.9Z" transform="translate(-12.5 -36.4)" fill="currentColor"/>
        </g>
        <g id="Group_127" data-name="Group 127" transform="translate(27.386 8.403)">
            <path id="Path_27" data-name="Path 27" d="M38.25,13.7h7.278a.75.75,0,1,0,0-1.5H38.25a.709.709,0,0,0-.75.75A.807.807,0,0,0,38.25,13.7Z" transform="translate(-37.5 -12.2)" fill="currentColor"/>
        </g>
        <g id="Group_128" data-name="Group 128" transform="translate(31.963 14.481)">
            <path id="Path_28" data-name="Path 28" d="M43.6,21.05a.709.709,0,0,0,.75.75h5.927a.75.75,0,1,0,0-1.5H44.35A.709.709,0,0,0,43.6,21.05Z" transform="translate(-43.6 -20.3)" fill="currentColor"/>
        </g>
        <g id="Group_129" data-name="Group 129" transform="translate(31.963 20.483)">
            <path id="Path_29" data-name="Path 29" d="M50.278,28.3H44.35a.75.75,0,0,0,0,1.5h5.927a.709.709,0,0,0,.75-.75A.752.752,0,0,0,50.278,28.3Z" transform="translate(-43.6 -28.3)" fill="currentColor"/>
        </g>
        <g id="Group_130" data-name="Group 130" transform="translate(31.963 26.561)">
            <path id="Path_30" data-name="Path 30" d="M50.278,36.4H44.35a.75.75,0,0,0,0,1.5h5.927a.709.709,0,0,0,.75-.75A.807.807,0,0,0,50.278,36.4Z" transform="translate(-43.6 -36.4)" fill="currentColor"/>
        </g>
        <g id="Group_131" data-name="Group 131" transform="translate(20.614 15.794)">
            <path id="Path_31" data-name="Path 31" d="M28.794,30.641a.682.682,0,0,0,.75,0l5.4-3.6a.682.682,0,0,0,.3-.6.926.926,0,0,0-.3-.6l-5.327-3.677a.682.682,0,0,0-.75,0,.788.788,0,0,0-.375.675v7.128A.606.606,0,0,0,28.794,30.641Zm1.125-6.453,3.3,2.176-3.3,2.176Z" transform="translate(-28.474 -22.05)" fill="currentColor"/>
        </g>
        <g id="Group_132" data-name="Group 132" transform="translate(0 0)">
            <path id="Path_32" data-name="Path 32" d="M49.02,1.75A.709.709,0,0,0,48.27,1H1.75A.752.752,0,0,0,1,1.75V48.27a.709.709,0,0,0,.75.75H48.27a.709.709,0,0,0,.75-.75Zm-1.5.75V39.716H2.5V2.5ZM2.5,47.519v-6.3H47.519v6.3Z" transform="translate(-1 -1)" fill="currentColor"/>
        </g>
        </g>
    </g>
    </svg>

</template>