<template>
    <svg id="_002-exam" data-name="002-exam" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.892 43.993">
        <path id="Path_80" data-name="Path 80" d="M52.945,13.625h-50A2.951,2.951,0,0,0,0,16.572V56.854a.764.764,0,0,0,.764.764H55.128a.764.764,0,0,0,.764-.764V16.572A2.951,2.951,0,0,0,52.945,13.625ZM1.528,16.572a1.421,1.421,0,0,1,1.419-1.419h50a1.421,1.421,0,0,1,1.419,1.419v5.487H1.528ZM54.364,56.09H1.528v-32.5H54.364Z" transform="translate(0 -13.625)" fill="currentColor"/>
        <path id="Path_81" data-name="Path 81" d="M12.866,23.282H11.638a.764.764,0,0,0,0,1.528h1.228a.764.764,0,1,0,0-1.528Z" transform="translate(-6.126 -19.065)" fill="currentColor"/>
        <path id="Path_82" data-name="Path 82" d="M24.96,23.282H23.732a.764.764,0,1,0,0,1.528H24.96a.764.764,0,1,0,0-1.528Z" transform="translate(-12.939 -19.065)" fill="currentColor"/>
        <path id="Path_83" data-name="Path 83" d="M37.053,23.282H35.825a.764.764,0,1,0,0,1.528h1.228a.764.764,0,0,0,0-1.528Z" transform="translate(-19.751 -19.065)" fill="currentColor"/>
        <path id="Path_84" data-name="Path 84" d="M10.729,75.739A2.729,2.729,0,1,0,8,73.01a2.729,2.729,0,0,0,2.729,2.729Zm0-3.93a1.2,1.2,0,1,1-1.2,1.2A1.2,1.2,0,0,1,10.729,71.809Z" transform="translate(-4.507 -45.542)" fill="currentColor"/>
        <path id="Path_85" data-name="Path 85" d="M10.729,99.333A2.729,2.729,0,1,0,8,96.6a2.729,2.729,0,0,0,2.729,2.729Zm0-3.93a1.2,1.2,0,1,1-1.2,1.2A1.2,1.2,0,0,1,10.729,95.4Z" transform="translate(-4.507 -58.833)" fill="currentColor"/>
        <path id="Path_86" data-name="Path 86" d="M62.091,70.281A2.729,2.729,0,1,0,64.82,73.01,2.729,2.729,0,0,0,62.091,70.281Zm0,3.93a1.2,1.2,0,1,1,1.2-1.2,1.2,1.2,0,0,1-1.2,1.2Z" transform="translate(-33.441 -45.542)" fill="currentColor"/>
        <path id="Path_87" data-name="Path 87" d="M62.091,93.875A2.729,2.729,0,1,0,64.82,96.6,2.729,2.729,0,0,0,62.091,93.875Zm0,3.93a1.2,1.2,0,1,1,1.2-1.2,1.2,1.2,0,0,1-1.2,1.2Z" transform="translate(-33.441 -58.833)" fill="currentColor"/>
        <path id="Path_88" data-name="Path 88" d="M8.764,42.468H56.142a.764.764,0,0,0,0-1.528H8.764a.764.764,0,0,0,0,1.528Z" transform="translate(-4.507 -29.013)" fill="currentColor"/>
        <path id="Path_89" data-name="Path 89" d="M8.764,51.653H56.142a.764.764,0,1,0,0-1.528H8.764a.764.764,0,0,0,0,1.528Z" transform="translate(-4.507 -34.187)" fill="currentColor"/>
        <path id="Path_90" data-name="Path 90" d="M8.764,60.838H41.213a.764.764,0,0,0,0-1.528H8.764a.764.764,0,1,0,0,1.528Z" transform="translate(-4.507 -39.361)" fill="currentColor"/>
        <path id="Path_91" data-name="Path 91" d="M31.051,69.217a1.139,1.139,0,0,0-1.092-.83h-.342a1.141,1.141,0,0,0-1.092.83l-1.5,5.312a.764.764,0,1,0,1.471.413l.451-1.607h1.667l.451,1.607a.764.764,0,0,0,.735.558.754.754,0,0,0,.207-.029.763.763,0,0,0,.531-.942Zm-1.669,2.59.4-1.441.4,1.441Z" transform="translate(-15.208 -44.475)" fill="currentColor"/>
        <path id="Path_92" data-name="Path 92" d="M31.124,91.98H29.06a.764.764,0,0,0-.764.764v5.585a.764.764,0,0,0,.764.764h2.064a2.163,2.163,0,0,0,2.161-2.161,2.14,2.14,0,0,0-.524-1.4,2.14,2.14,0,0,0,.524-1.4,2.163,2.163,0,0,0-2.161-2.158Zm-1.3,1.528h1.3a.632.632,0,1,1,0,1.264h-1.3Zm1.3,4.057h-1.3V96.3h1.3a.632.632,0,1,1,0,1.264Z" transform="translate(-15.94 -57.766)" fill="currentColor"/>
        <path id="Path_93" data-name="Path 93" d="M80.584,69.915H82.3a.764.764,0,0,0,0-1.528h-1.72A2.216,2.216,0,0,0,78.37,70.6v2.685A2.216,2.216,0,0,0,80.584,75.5H82.3a.764.764,0,0,0,0-1.528h-1.72a.686.686,0,0,1-.686-.686V70.6A.686.686,0,0,1,80.584,69.915Z" transform="translate(-44.149 -44.475)" fill="currentColor"/>
        <path id="Path_94" data-name="Path 94" d="M80.855,91.98h-1.72a.764.764,0,0,0-.764.764v5.585a.764.764,0,0,0,.764.764h1.72a2.217,2.217,0,0,0,2.214-2.214V94.194a2.217,2.217,0,0,0-2.214-2.214Zm.686,4.9a.686.686,0,0,1-.686.686H79.9V93.508h.956a.686.686,0,0,1,.686.686Z" transform="translate(-44.149 -57.766)" fill="currentColor"/>
    </svg>
</template>