<template>
  <span>
    {{ value }}<sup>{{positionView}}</sup>
  </span>
</template>

<script>
export default {
  name: "position",
  props: { value:{ type: ( Number ) } },
  computed: {
    positionView(){
      const num = Number( this.value );

      switch ( num > 20  ? num % 10 : num ) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }

    }
  }
}
</script>