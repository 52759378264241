<template>

      <div class="w-full">

          <div class="max-w-xl mx-auto py-3 rounded-md shadow-lg bg-white p-4">

              <div class="mt-2 flex items-center">
                  <div class="flex flex-shrink-0 rounded-full border border-gray-200">
                      <img :src="review.studentImageUrl" alt="" class="w-10 h-10 object-cover rounded-full">
                  </div>
                  <span class="text-sm font-semibold leading-5 text-gray-900 ml-2">{{ review.studentName }}</span>
              </div>

              <div class="mt-2 text-left">
                  <p class="text-sm font-semibold leading-5 text-gray-800"> Medical College: {{ review.medicalCollege }} </p>
                  <p class="text-sm font-semibold leading-5 text-gray-800"> Unit: {{ review.unit }} </p>
                  <p class="text-sm font-semibold leading-5 text-gray-800"> Merit Position: <position :value="review.position"></position> </p>
              </div>

              <div style="height: 200px" class="overflow-y-hidden">
                  <!-- <h3 class="font-semibold text-gray-800 text-left mt-3">Review :</h3> -->
                  <p class="text-sm font-medium leading-5 text-gray-600 text-left mt-5">{{ review.reviewDetails }}</p>
              </div>

              <div class="text-right mt-4">

                  <btn :router-path="`/student-reviews/${ review.id }`"  class="" variant="warning">
                    <span class="inline-block">
                      Continue Reading
                    </span>
                    <span class="inline-block">
                      <svg class="w-3 h-3 ml-1 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      </svg>
                    </span>
                  </btn>

              </div>
          </div>

      </div>

<!--  <div>{{ review }}</div>-->

</template>

<script>

    // import { Slide } from 'vue3-carousel';
    import Position from '../../../student/exams/components/position.vue';
    import Btn from "../../../../components/btn";

    export default {
        name: 'student-review-homepage-item',

        components: {
          Btn,
            Position
        },

        props: {
            review: { type: Object, default: {} }
        }
    };

</script>
