<template>
    <div class="w-full lg:w-1/2 px-4 py-2">
        <button @click="show = !show" class="flex flex-row justify-between w-full text-left font-semibold bg-gray-200 rounded-t-md py-2 pl-4 pr-6">
            {{ faq.title }}
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                </svg>
            </span>
        </button>

        <Transition name="slide-fade">
            <p v-if="show" class="ck-content bg-gray-200 rounded-b-md text-justify p-3" v-html="faq.body"></p>
        </Transition>

        <Transition name="slide-fade">
            <div v-if="show">
                <div class="switch-toggle switch-3 switch-candy rounded-full mt-2">
                    <input id="on" name="state-d" type="radio" :disabled="isDisabled" @click="feedback(faq.id, 'negative')" checked="" />
                    <label for="on" class="text-red-500">✖</label>

                    <input id="na" name="state-d" type="radio" checked="checked" />
                    <label for="na" class="disabled text-white font-medium" :disabled="isDisabled">useful</label>

                    <input id="off" name="state-d" type="radio" :disabled="isDisabled" @click="feedback(faq.id, 'positive')" />
                    <label for="off" class="text-green-500">✔</label>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
    export default {
        name: 'faq-card',
        components: {

        },
        data() {
            return {
                show: '',
                isDisabled: false,
            }
        },
        props: {
            faq: {
                type: Object,
                default: {}
            }
        },
        methods: {
            feedback(faq_id, feedback) {
                this.loading = true;
                this.formErrors = [];

                this.$store.dispatch('faqs/feedback', {
                    data: {
                        faq_id,
                        feedback
                    },
                })
                .finally(() => {
                    this.loading = false;
                    this.isDisabled = true;
                });
            }
        }
    }
</script>

<style>
    .slide-fade-enter-active {
        transition: all 0.5s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(20px);
        opacity: 0;
    }

    .switch-toggle {
        float: right;
        margin-bottom: 5px;
        background: #cacaca;
    }
    .switch-toggle input {
        position: absolute;
        opacity: 0;
    }
    .switch-toggle input + label {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
        float:left;
        cursor: pointer;
    }
    .switch-toggle input:checked + label {
        border-radius: 50px;
        background: rgb(145, 145, 145);
    }
</style>