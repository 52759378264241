<template>
  <router-link class="shadow-lg border-4 border-gray-200 
      hover:bg-blue-200
      hover:border-blue-200 rounded p-4 text-gray-500 hover:text-blue-600" 
              :to="`/courses?course_id=${course.id}`" :key="course.id">
        <div class="w-full h-full">
            <div class="text-center font-bold">
                <div class="p-3 ">{{ course.name }}</div>     
            </div>
        </div>
  </router-link>
</template>

<script>
export default {
  name: "course-item",
  props:{
    course:{}
  }

};
</script>

