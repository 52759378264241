<template>
    <div>
        <div class="relative mt-3">
            <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                <notice-item v-for="(notice, index) in NoticeItems" :notice="notice"/>
            </div>
        </div>

        <div class="text-center mt-8">
            <btn router-path="/notices" variant="info" href="">Show All Notice</btn>
        </div>
    </div>
</template>

<script>
    import Btn from "../../../../components/btn.vue";
    import NoticeItem from "../../notices/notice-item.vue";

    export default {
        name: 'notices',
        components: {
            Btn, NoticeItem
        },
        computed: {
            NoticeItems(){
                return Array.isArray(this.data.items) ? this.data.items:[];
            }
        },
        props: {
            data: {
                type: Object,
                default: {}
            }
        }
    }
</script>

<style scoped>

</style>