<template>
    <div>
        <div class="w-full flex-col md:flex-row justify-center items-center">
            <div class="max-w-sm rounded-md overflow-hidden shadow-lg">
                <div class="w-full bg-blue-600 px-5 py-3">
                    <div class="text-white font-semibold text-xl">{{ notice.title }}</div>
                    <p class="text-white text-sm">{{ notice.displayDate }} {{ notice.displayTime }}</p>
                </div>
                
                <div style="height: 125px" class="overflow-y-hidden px-5 py-3">
                    <p class="text-gray-500" v-html="notice.body"></p>
                </div>

                <div class="text-right m-2">
                    <btn :router-path="`/notices/${ notice.id }`" variant="" class="text-blue-600 font-serif shadow-none">See More</btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Btn from '../../../components/btn.vue';

    export default {
        name: 'notice-item',
        components: {
            Btn
        },
        props: {
            notice: {
                type: Object,
                default: {}
            }
        }
    };
</script>