<template>
    <svg id="_004-office" data-name="004-office" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.345 62.78">
        <path id="Path_104" data-name="Path 104" d="M68.435,56.528h-4.3V9.5a3.027,3.027,0,0,0-3.193-2.82H43.206V.857A.921.921,0,0,0,42.235,0H25.994a.921.921,0,0,0-.972.857V6.678H23.53A3.028,3.028,0,0,0,20.337,9.5V56.528h-4.3a.921.921,0,0,0-.972.858v4.535a.921.921,0,0,0,.972.858h52.4a.92.92,0,0,0,.972-.858V57.386a.92.92,0,0,0-.972-.858ZM26.965,1.717h14.3V6.678h-14.3ZM22.28,9.5A1.185,1.185,0,0,1,23.53,8.4h37.41a1.184,1.184,0,0,1,1.249,1.1V56.528H49.453V47.209h2.428a.92.92,0,0,0,.972-.858V42.49a.92.92,0,0,0-.972-.858H32.587a.92.92,0,0,0-.972.858v3.86a.92.92,0,0,0,.972.858h2.429v9.319H22.28ZM33.559,45.49V43.346H50.911V45.49Zm3.4,1.717H47.51v9.319H36.96ZM67.464,61.063H17.005V58.245H67.464Z" transform="translate(-15.062 0)" fill="currentColor"/>
        <path id="Path_105" data-name="Path 105" d="M35.534,30.912H46.812a.972.972,0,0,0,.972-.972V24.588a.971.971,0,0,0-.972-.972H35.534a.972.972,0,0,0-.972.972V29.94A.972.972,0,0,0,35.534,30.912Zm.972-5.353h9.335v3.409H36.505Z" transform="translate(-23.735 -12.208)" fill="currentColor"/>
        <path id="Path_106" data-name="Path 106" d="M35.534,51.717H46.812a.971.971,0,0,0,.972-.972V45.393a.972.972,0,0,0-.972-.972H35.534a.972.972,0,0,0-.972.972v5.353A.972.972,0,0,0,35.534,51.717Zm.972-5.353h9.335v3.409H36.505Z" transform="translate(-23.735 -22.963)" fill="currentColor"/>
        <path id="Path_107" data-name="Path 107" d="M47.784,71.551V66.2a.971.971,0,0,0-.972-.972H35.534a.972.972,0,0,0-.972.972v5.353a.972.972,0,0,0,.972.972H46.812A.972.972,0,0,0,47.784,71.551Zm-1.943-.972H36.505V67.17h9.335Z" transform="translate(-23.735 -33.718)" fill="currentColor"/>
        <path id="Path_108" data-name="Path 108" d="M70.6,30.912H81.875a.972.972,0,0,0,.972-.972V24.588a.971.971,0,0,0-.972-.972H70.6a.971.971,0,0,0-.972.972V29.94A.972.972,0,0,0,70.6,30.912Zm.972-5.353H80.9v3.409H71.568Z" transform="translate(-39.329 -12.208)" fill="currentColor"/>
        <path id="Path_109" data-name="Path 109" d="M70.6,51.717H81.875a.971.971,0,0,0,.972-.972V45.393a.972.972,0,0,0-.972-.972H70.6a.972.972,0,0,0-.972.972v5.353a.971.971,0,0,0,.972.972Zm.972-5.353H80.9v3.409H71.568Z" transform="translate(-39.329 -22.963)" fill="currentColor"/>
        <path id="Path_110" data-name="Path 110" d="M70.6,72.523H81.875a.972.972,0,0,0,.972-.972V66.2a.971.971,0,0,0-.972-.972H70.6a.971.971,0,0,0-.972.972v5.353A.972.972,0,0,0,70.6,72.523Zm.972-5.353H80.9V70.58H71.568Z" transform="translate(-39.329 -33.718)" fill="currentColor"/>
    </svg>
</template>