<template>
  <div>

    <h2 class="text-bold text-xl font-bold mb-2">{{ course.name }}</h2>

    <div class="grid md:grid-cols-3 grid-cols-1 gap-4">
        <batch-item v-for="(batch) in course.available_batches"
                    :item="batch"
                    :key="batch.program_id"
                    :enroll="false">
        </batch-item>
    </div>

    <div class="flex justify-end mt-4 mb-6 pb-4 border-b">
      <btn variant="info"
           :router-path="`/courses?course_id=${course.id}`">See more</btn>
    </div>

  </div>

</template>

<script>
 import BatchItem from './batch-item.vue';
 import Btn from '../../../../components/btn.vue';

  export default {
    name: "course-item",
    components: { BatchItem, Btn },
    props: {
      course:{}
    }
  };

</script>
  
  