<template>
  <div>
    <div class="w-full text-center text-md md:text-2xl lg:text-4xl text-green-400 font-bold">Welcome to
      {{ branchName }}
    </div>
    <div class="w-full flex md:flex-row flex-col justify-between items-center md:px-4 gap-4 md:gap-8 lg:gap-16 ">
      <div class="w-full max-w-xs md:max-w-md  lg:max-w-lg order-2 md:order-1 text-center md:text-left">

        <h1 class="text-blue-600 text-xl md:text-3xl lg:text-3xl font-bold leading-tight">
          <p class="block">Dreaming to be a doctor !!!</p>
          <p class="block">Your Choice should be Retina</p>
        </h1>

        <p class="text-gray-700 text-sm lg:text-base my-4 md:my-8 text-justify">
          Year after year, the largest number of student from <b class="font-bold">Retina</b> are getting admitted in
          medical colleges
        </p>

        <template v-if="admission_forms.length > 0 ">
          <router-link class="px-4 py-1.5 rounded-sm text-white bg-green-600" to="/data-collections/1">
            Admission Forms
          </router-link>
          <p v-if="!isLoggedIn( )" class="mt-4">
            If Already admitted <btn router-path="/join" variant="success-outline" size="sm">Login Now</btn>
          </p>

        </template>


        <template v-else>
          <router-link v-if="!isLoggedIn( )" class="px-4 py-1.5 rounded-sm text-white bg-green-600" to="/join">
            Student Login
          </router-link>
          <router-link v-else class="px-4 py-1.5 rounded-sm text-white bg-blue-600" to="/courses">
            Courses
          </router-link>

        </template>

      </div>
      <div class="w-full max-w-xs md:max-w-md lg:max-w-lg md:order-2 order-1">
        <banner-svg class="w-full"/>
      </div>
    </div>
  </div>
</template>

<script>
import BannerSvg from './banner-svg.vue';
import axios from "../../../../store/axios";
import Btn from "../../../../components/btn";

export default {
  components: {
    Btn,
    BannerSvg,
  }
  ,
  data() {
      return {
        admission_forms: []
      }
  },
  computed: {
    branchName() {
      const appData = this.$store.state.appData || {};
      return appData.name || 'Retina';
    }
  },
  created() {

    this.$store.dispatch( 'data_collections/get_forms' ).then( (forms) => {
      this.admission_forms = Array.isArray( forms ) ? forms : [ ];
    });


  }
}
</script>