<template>
    <div class="mb-8">

      <div class="w-full flex flex-col md:flex-row mb-4 md:py-2 rounded justify-center items-center">
        <student-review-homepage-item

            v-for="(review, index) in slideItems" :review="review"
            class="slide-item md:w-4/12 bg-opacity-25 mx-2 px-4 md:px-1 py-8 md:py-1 p-1 rounded"
            :class="{'active': index == 0 }"/>
      </div>


      <div class="text-center">
        <btn router-path="/student-reviews" variant="info" href="">Show All</btn>
      </div>
    </div>

</template>

<script>

    import { Carousel } from 'vue3-carousel';
    import StudentReviewHomepageItem from './student-review-homepage-item.vue';
    import Btn from "../../../../components/btn";

    export default {
        name: 'student-reviews',
        components: {
          Btn, Carousel, StudentReviewHomepageItem
        },
        computed: {
          slideItems(){
            return Array.isArray(this.data.items) ? this.data.items:[];
          }
        },
        props: {
            data: { type: Object, default: {} }
        },
        methods: {
          onSwiper(){

          },
          onSlideChange(){

          }
        }
    };
</script>

<style scoped>

    .slide-item {

    }

    .carousel__slide > .carousel__item {
        /* @apply scale-100 opacity-50; */


        transform: scale(1);
        opacity: 0.5;
        transition: 0.5s;
    }
    .carousel__slide--visible > .carousel__item {
        opacity: 1;
        transform: rotateY(0);
    }
    .carousel__slide--next > .carousel__item {
        transform: scale(0.9) translate(-10px);
    }
    .carousel__slide--prev > .carousel__item {
        transform: scale(0.9) translate(10px);
    }
    .carousel__slide--active > .carousel__item {
        transform: scale(1.1);
    }
</style>