<template>
    <div class="relative mt-3">
        <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">
            <TeacherItemVue v-for="teacher in TeacherItems" :teacher="teacher"/>
        </div>

        <div class="text-center mt-5">
            <BtnVue router-path="/teachers-panel" variant="info">Show All</BtnVue>
        </div>
    </div>
</template>

<script>
    import TeacherItemVue from '../../teacher/teacher-item.vue';
    import BtnVue from '../../../../components/btn.vue';
    
    export default {
        name: 'teacher-section',
        components: {
            TeacherItemVue,
            BtnVue
        },
        computed: {
            TeacherItems(){
                return Array.isArray(this.data.items) ? this.data.items:[];
            }
        },
        props: {
            data: {
                type: Object,
                default: {}
            }
        }
    }
</script>