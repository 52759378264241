<template lang="">
    <div>
        <div class="flex flex-wrap lg:w-4/4 sm:mx-auto sm:mb-2">
            <FaqCard class="text-center" v-for="faq in FaqsItems" :faq="faq"/>
        </div>

        <div class="text-center mt-4">
            <btn router-path="/faq" variant="info" href="">Show All FAQ</btn>
        </div>
    </div>
</template>

<script>
    import Btn from "../../../../components/btn.vue";
    import FaqCard from '../../faq/faq-card.vue';

    export default {
        name: 'faqs',
        components: {
            Btn,
            FaqCard
        },
        computed: {
            FaqsItems(){
                return Array.isArray(this.data.items) ? this.data.items:[];
            }
        },
        props: {
            data: {
                type: Object,
                default: {}
            }
        }
    }
</script>

<style lang="">
    
</style>