<template>
    <svg id="Online_course" data-name="Online course" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.744 45.48">
        <path id="Path_111" data-name="Path 111" d="M62.007,64H20.317a4.744,4.744,0,0,0-4.657,3.871,4.749,4.749,0,0,0-3.79,3.79A4.744,4.744,0,0,0,8,76.317v28.425a4.743,4.743,0,0,0,4.737,4.737h41.69a4.744,4.744,0,0,0,4.657-3.871,4.749,4.749,0,0,0,3.79-3.79,4.744,4.744,0,0,0,3.871-4.657V68.737A4.743,4.743,0,0,0,62.007,64ZM9.895,76.317a2.846,2.846,0,0,1,2.842-2.842h41.69a2.846,2.846,0,0,1,2.842,2.842v.947H9.895ZM57.27,104.742a2.846,2.846,0,0,1-2.842,2.842H12.737a2.846,2.846,0,0,1-2.842-2.842V79.16H57.27Zm3.79-3.79a2.847,2.847,0,0,1-1.895,2.68V76.317a4.743,4.743,0,0,0-4.737-4.737H13.848a2.847,2.847,0,0,1,2.68-1.895h41.69a2.846,2.846,0,0,1,2.842,2.842Zm3.79-3.79a2.847,2.847,0,0,1-1.895,2.68V72.527a4.743,4.743,0,0,0-4.737-4.737H17.638a2.847,2.847,0,0,1,2.68-1.895h41.69a2.846,2.846,0,0,1,2.842,2.842Z" transform="translate(-8 -64)" fill="currentColor"/>
        <ellipse id="Ellipse_14" data-name="Ellipse 14" cx="0.971" cy="0.971" rx="0.971" ry="0.971" transform="translate(3.745 10.388)" fill="currentColor"/>
        <ellipse id="Ellipse_15" data-name="Ellipse 15" cx="0.902" cy="0.971" rx="0.902" ry="0.971" transform="translate(7.629 10.388)" fill="currentColor"/>
        <ellipse id="Ellipse_16" data-name="Ellipse 16" cx="0.971" cy="0.971" rx="0.971" ry="0.971" transform="translate(11.374 10.388)" fill="currentColor"/>
        <path id="Path_112" data-name="Path 112" d="M128.947,233.055h20.845a.948.948,0,0,0,.947-.947v-15.16a.947.947,0,0,0-.947-.947H128.947a.947.947,0,0,0-.947.947v15.16A.948.948,0,0,0,128.947,233.055Zm.947-15.16h18.95V231.16h-18.95Z" transform="translate(-113.788 -197.998)" fill="currentColor"/>
        <path id="Path_113" data-name="Path 113" d="M200.486,247.458a.947.947,0,0,0,.959-.02l4.619-2.842a.948.948,0,0,0,0-1.614l-4.619-2.842a.947.947,0,0,0-1.444.807v5.685A.947.947,0,0,0,200.486,247.458Zm1.409-4.816,1.864,1.147-1.864,1.147Z" transform="translate(-177.26 -219.154)" fill="currentColor"/>
        <path id="Path_114" data-name="Path 114" d="M174.212,304.947h-1.895a.947.947,0,1,0-1.895,0h-9.475a.947.947,0,1,0,0,1.895h9.475a.947.947,0,1,0,1.895,0h1.895a.947.947,0,0,0,0-1.895Z" transform="translate(-141.998 -275.575)" fill="currentColor"/>
        <path id="Path_115" data-name="Path 115" d="M128.947,369.895h20.845a.947.947,0,1,0,0-1.895H128.947a.947.947,0,1,0,0,1.895Z" transform="translate(-113.788 -331.995)" fill="currentColor"/>
        <path id="Path_116" data-name="Path 116" d="M128.947,393.895h13.265a.947.947,0,1,0,0-1.895H128.947a.947.947,0,1,0,0,1.895Z" transform="translate(-113.788 -353.153)" fill="currentColor"/>
        <path id="Path_117" data-name="Path 117" d="M265.895,392h-.948a.947.947,0,0,0,0,1.895h.948a.947.947,0,0,0,0-1.895Z" transform="translate(-233.68 -353.153)" fill="currentColor"/>
    </svg>

</template>