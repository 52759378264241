<template>

  <div>

    <home-section class="" min-height="auto">
      <template #body>
        <banner-section class="" />
      </template>
    </home-section>

    <home-section class="mt-9 -mb-5 md:-mb-20" min-height="20" data-key="noticeViews" items-key="notices">
      <template #body="data">
        <NoticeScrollVue :data="data" />
      </template>
    </home-section>

    <home-section class="mt-12 md:mt-16" min-height="200" 
          data-key="courseList" 
          items-key="courses"        
    >
      <template #title>কোন কোর্সটি আপনার জন্য ?</template>
      <template #body="{items}">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full " style="place-content: center;">
          <!-- {{ items }} -->
          <course-item v-for="course in items" :course="course" :key="course.id"/>
        </div>
      </template>
    </home-section>

    
    <home-section class="mt-12 md:mt-16" min-height="200" data-key="availableBatches" 
          items-key="courses" 
              :data-params="{
                  branchFiltering: 'true' ,
                  group_by_course : 'true',
                  homepage_items_only: 'true'
              }"
    >
      <template #title>Course Batch List</template>
      <template #body="{items }">
        <course-batch-item v-for="course in items" :course="course" :key="course.id"></course-batch-item>  
      </template>
    </home-section>

    <home-section class="mt-12 md:mt-16" min-height="200">
      <template #body>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
          <quick-link-item />
        </div>
      </template>
    </home-section>


    <home-section class="mt-10 md:mt-5" data-key="teacherViews" items-key="teachers" :data-params="{ perPage: 3 }">
      <template #title>Teachers Panel</template>

      <template #body="data">
        <TeacherSectionVue :data="data" />
      </template>
    </home-section>

    <home-section
        class="mt-12 md:mt-10 mb-6 md:mb-12"
        data-key="participantReviews"
        items-key="reviews"
        :data-params="{ perPage: 3 }"
    >
      <template #title>Student Reviews</template>

      <template #body="data">
        <student-reviews :data="data"/>
      </template>
    </home-section>

    <home-section class="mt-12 md:mt-16" data-key="noticeViews" items-key="notices" :data-params="{perPage: 3}">
      <template #title>Notice Board</template>

      <template #body="data">
        <notices :data="data"/>
      </template>
    </home-section>

    <home-section class="mt-10 md:mt-5" data-key="blogViews" items-key="posts">
      <template #title>Retina Blog</template>

      <template #body="data">
        <Blogs :data="data" />
      </template>
    </home-section>

    <home-section class="mt-12 md:mt-4" data-key="faqsViews" items-key="faqs" :data-params="{perPage: 4}">
      <template #title>Frequently Asked Question</template>

      <template #body="data">
        <faqs :data="data"/>
      </template>
    </home-section>

  </div>

</template>

<script>
import HomeSection from "./components/home-section.vue";
import BannerSection from "./components/banner-section.vue";
import QuickLinkItem from './components/quick-link-item.vue';
import CourseItem from './components/course-item.vue';
import CourseBatchItem from './components/course-batch-item.vue';
import BatchItem from "./components/batch-item.vue";
import StudentReviews from './components/student-reviews.vue';
import Notices from './components/notices.vue';
import NoticeScrollVue from "./components/notice-scroll.vue";
import Blogs from './components/blogs.vue';
import TeacherSectionVue from "./components/teacher-section.vue";
import Faqs from "./components/faqs.vue";
export default {
  name: "Home",
  components: {
    CourseBatchItem,
    HomeSection,
    BannerSection,
    QuickLinkItem,
    CourseItem,
    BatchItem,
    StudentReviews,
    Notices,
    NoticeScrollVue,
    Blogs,
    TeacherSectionVue,
    Faqs
  },
  computed:{
    batchFilter( ){
      return ( item ) => item.visibleInHomepage;
    }
  },
  methods: {}
};
</script>

<style scoped>

</style>
