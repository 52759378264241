<template>
  <div :data-key="dataKey">

    <div class="relative w-full max-w-6xl mx-auto md:p-3">

      <div class="w-full my-3"  v-if="$slots.title || $slots.subtitle">
        <h2 class="text-center font-bold text-xl md:text-3xl text-blue-500 mb-2" v-if="$slots.title">
          <slot name="title"></slot>
        </h2>
        <p class="text-center font-semibold text-base md:text-lg text-gray-500" v-if="$slots.subtitle">
          <slot name="subtitle"></slot>
        </p>
      </div>
      
      <div class="my-3 relative" :style="`min-height: ${minHeight}`">
        <overly class="rounded" background="bg-blue-100" v-if="isLoading">
          <spinner class="text-red-600 h-10 w-10"></spinner>
        </overly>
        <div v-fade-in="!isLoading" >
          <slot name="body" :items="items" ></slot>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import Overly from "../../../../components/overly";
import Spinner from "../../../../components/spinner";
export default {
  name: "home-section",
  props: {
    id: { type: String, default: "" },
    itemsKey: { type: String, default: "items" },
    dataParams: { type: Object, default: {} },
    dataKey: String,
    minHeight: { type: String, default: '400px' } ,
    itemFilter: { type: Function, default: null }
  },
  components: {Spinner, Overly},
  data() {
    return {
      items: [],
      loaded: false,
      focused: false,
      loading: true,
    };
  },
  created() {
    this.items = [];
  },

  methods: {
    loadItems() {
      this.$store
        .dispatch(this.actionPath, {
          params: this.dataParams,
          success: ({data}) => {


            
            if( data ) {
              const items = data[this.itemsKey];
              if( Array.isArray( items ) ) {
                if( typeof this.itemFilter == 'function' ) {
                  this.items = items.filter( this.itemFilter );
                } else {
                  this.items = items;
                }
              }
            }

            console.log({DDDDDDDD: data });

          },
          error: (err) => { console.warn( err )},
        })
        .finally(() => {
          this.loaded = true;
          this.loading = false
        });
    },

    loadWhenFocus() {

      const element = document.querySelector(`[data-key='${this.dataKey}']`);


      if (element) {
        let position = element.getBoundingClientRect();
        if (
          this.focused === false &&
          position.top >= 0 &&
          position.top <= window.innerHeight
        ) {
          // API Call
          this.focused = true;
          if (!this.loaded) {
            this.loadItems();
          }
        }
      }

    },
  },

  mounted() {
    setTimeout( this.loadWhenFocus, 500 );
    setTimeout( this.loadWhenFocus, 1000 );
    setTimeout( this.loadWhenFocus, 2000 );
    setTimeout( this.loadWhenFocus, 3000 );
    window.addEventListener("scroll", this.loadWhenFocus );
    window.addEventListener("resize", this.loadWhenFocus );

  },

  computed: {
    actionPath() {
      return (
        "public/" +
        String(this.dataKey).replace(/\-([a-z])/g, (m, g) => String(g).toUpperCase())
      );
    },
    isLoading() {
      if(this.dataKey) {
        return this.loading;
      }
      return false;
    }
  },
};
</script>
